var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_bg-default _80w _100vh d-flex"},[_c('div',{staticClass:"_100top _full-w pa-3"},[_c('v-card',{staticClass:"radius-card pb-3",attrs:{"loading":_vm.loading,"min-height":"150px"}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('h3',[_vm._v("Forum Management")])]),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v("Forum List")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v("Moderator")])],1),_c('v-tabs-items',{staticStyle:{"background":"none"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[(_vm.forum)?_c('div',[_c('v-data-table',{staticClass:"elevation-0 my-5",attrs:{"headers":_vm.headers,"items":_vm.forum,"item-class":_vm.isClosed,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.perPage},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.creator",fn:function(ref){
var item = ref.item;
return _vm._l((item.relationships),function(creator,idx){return _c('div',{key:(idx + "-user")},[(creator.user)?_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(creator.user.data.nama_lengkap)+" ")]):_vm._e()])})}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.created_at).format("DD, MMMM, YYYY"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                      arrow: true,
                      arrowType: 'round',
                      animation: 'fade',
                      theme: 'light',
                      maxWidth: 100
                    }),expression:"{\n                      arrow: true,\n                      arrowType: 'round',\n                      animation: 'fade',\n                      theme: 'light',\n                      maxWidth: 100\n                    }"}],staticClass:"mr-2",attrs:{"icon":"","depressed":"","outlined":"","color":item.status == 'open' ? 'green' : 'white',"content":"View thread","to":("/psikolog/thread/" + (item.id))}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-eye ")])],1),(item.status == 'open')?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                      arrow: true,
                      arrowType: 'round',
                      animation: 'fade',
                      theme: 'light',
                      maxWidth: 100
                    }),expression:"{\n                      arrow: true,\n                      arrowType: 'round',\n                      animation: 'fade',\n                      theme: 'light',\n                      maxWidth: 100\n                    }"}],attrs:{"icon":"","depressed":"","outlined":"","color":"red","content":"Close thread"},on:{"click":function($event){$event.preventDefault();return _vm.closeItem(item)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-cancel ")])],1):_vm._e(),(item.status == 'closed')?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                      arrow: true,
                      arrowType: 'round',
                      animation: 'fade',
                      theme: 'light',
                      maxWidth: 100
                    }),expression:"{\n                      arrow: true,\n                      arrowType: 'round',\n                      animation: 'fade',\n                      theme: 'light',\n                      maxWidth: 100\n                    }"}],attrs:{"icon":"","depressed":"","outlined":"","color":"white","content":"open thread"},on:{"click":function($event){$event.preventDefault();return _vm.closeItem(item)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-lock-open ")])],1):_vm._e()],1)]}}],null,true)}),(_vm.loading)?_c('div',{staticClass:"text-center"},[_vm._v("Memuat ...")]):_vm._e(),_c('div',{staticClass:"text-center pa-3"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.last_page},on:{"input":_vm.fetchItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()]),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('ModeratorManagement')],1)],1)],1)],1),_c('div',{staticClass:"pb-1"},[_c('CloseThread',{attrs:{"closeThread":_vm.closeThread,"item":_vm.item},on:{"close":_vm.closeDialog}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }