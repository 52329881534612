<template>
  <div>
    <v-card class="radius-card pb-3" :loading="loading" min-height="150px">
      <v-toolbar dense flat></v-toolbar>
      <div>
        <v-tabs vertical v-if="fields" v-model="tab">
          <v-tab
            v-for="(field, idx) in fields"
            :key="idx"
            :href="`#${field.nama}`"
            @click="getModerator()"
          >
            {{ field.nama }}
          </v-tab>

          <v-tab-item
            v-for="(field, idx) in fields"
            :key="`y-${idx}`"
            v-model="field.nama"
          >
            <div class="pa-3">
              <v-card elevation="1" min-height="300px">
                <v-toolbar flat dense>
                  <v-spacer></v-spacer
                  ><v-btn
                    dark
                    color="blue"
                    outlined
                    rounded
                    @click="assignMod(field.nama)"
                    >Assign Moderator</v-btn
                  ></v-toolbar
                >
                <div v-if="mod && !loading" class="pa-2">
                  <div v-for="moderator in mod.data" :key="moderator.id">
                    <v-card
                      class="radius-card ma-2 pa-3 d-flex justify-space-between align-center"
                      v-if="moderator.user_id !== 1"
                    >
                      <div
                        v-for="user in moderator.relationships"
                        :key="user.data.id"
                      >
                        <div class="d-flex align-center">
                          <v-avatar>
                            <img
                              :src="
                                user.data.photo_profile
                                  ? `${env}/upload/photo_profile/${moderator.user_id}/${user.data.photo_profile}`
                                  : `${env}/images/logo.png`
                              "
                              class="img-fit"
                              alt="masak"
                            />
                          </v-avatar>
                          <p class="ma-0 ml-3">{{ user.data.nama_lengkap }}</p>
                        </div>
                      </div>
                      <v-btn
                        fab
                        dark
                        small
                        depressed
                        color="red"
                        content="unassign moderator"
                        v-tippy="{
                          arrow: true,
                          arrowType: 'round',
                          animation: 'fade',
                          theme: 'light',
                          maxWidth: 100
                        }"
                        @click="unAssign(moderator)"
                        ><v-icon>mdi-cancel</v-icon></v-btn
                      >
                    </v-card>
                    <div></div>
                  </div>
                </div>
                <div class="d-flex justify-center" v-else>loading...</div>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>
      <div class="pb-1">
        <AssignModerator
          v-bind:dialogMod="dialogMod"
          @close="closeDialog"
          v-bind:item="item"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import AssignModerator from "../Admin/Modal/assignModerator";
import { mapState } from "vuex";

export default {
  name: "modManagement",
  components: {
    AssignModerator
  },

  computed: {
    ...mapState({
      env: state => state.API_URL,
      fields: state => state.forum.category,
      mod: state => state.forum.mod
    })
  },

  data() {
    return {
      editArticle: false,
      dialogMod: false,
      item: null,
      search: "",
      loading: false,
      page: 1,
      perPage: 5,
      tab: "Psikologi Klinis"
    };
  },

  mounted() {
    this.getFields();
    this.getModerator();
  },

  methods: {
    getFields() {
      this.loading = true;
      this.$store.dispatch("forum/category").then(() => {
        this.loading = false;
      });
    },
    getModerator() {
      this.loading = true;
      setTimeout(
        () =>
          this.$store.dispatch("forum/listModerator", this.tab).then(() => {
            this.loading = false;
          }),
        500
      );
    },

    assignMod(item) {
      this.item = item;
      this.dialogMod = true;
    },

    check(item) {
      console.log(item);
    },

    unAssign(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          let data = {
            id_user: item.user_id,
            category: item.nama_kategori
          };
          this.$store
            .dispatch("forum/unassignMod", data)
            .then(data => {
              this.getModerator(); //if success refetch data all
              Swal.fire({
                title: data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            })
            .catch(err => {
              Swal.fire({
                title: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },

    closeDialog() {
      //close dialog
      this.dialogMod = false;
      this.getFields(); //and refetch data
      this.getModerator();
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 90%;
}
</style>
