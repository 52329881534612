var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"radius-card pb-3",attrs:{"loading":_vm.loading,"min-height":"150px"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}}),_c('div',[(_vm.fields)?_c('v-tabs',{attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.fields),function(field,idx){return _c('v-tab',{key:idx,attrs:{"href":("#" + (field.nama))},on:{"click":function($event){return _vm.getModerator()}}},[_vm._v(" "+_vm._s(field.nama)+" ")])}),_vm._l((_vm.fields),function(field,idx){return _c('v-tab-item',{key:("y-" + idx),model:{value:(field.nama),callback:function ($$v) {_vm.$set(field, "nama", $$v)},expression:"field.nama"}},[_c('div',{staticClass:"pa-3"},[_c('v-card',{attrs:{"elevation":"1","min-height":"300px"}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"blue","outlined":"","rounded":""},on:{"click":function($event){return _vm.assignMod(field.nama)}}},[_vm._v("Assign Moderator")])],1),(_vm.mod && !_vm.loading)?_c('div',{staticClass:"pa-2"},_vm._l((_vm.mod.data),function(moderator){return _c('div',{key:moderator.id},[(moderator.user_id !== 1)?_c('v-card',{staticClass:"radius-card ma-2 pa-3 d-flex justify-space-between align-center"},[_vm._l((moderator.relationships),function(user){return _c('div',{key:user.data.id},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',[_c('img',{staticClass:"img-fit",attrs:{"src":user.data.photo_profile
                                ? (_vm.env + "/upload/photo_profile/" + (moderator.user_id) + "/" + (user.data.photo_profile))
                                : (_vm.env + "/images/logo.png"),"alt":"masak"}})]),_c('p',{staticClass:"ma-0 ml-3"},[_vm._v(_vm._s(user.data.nama_lengkap))])],1)])}),_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                        arrow: true,
                        arrowType: 'round',
                        animation: 'fade',
                        theme: 'light',
                        maxWidth: 100
                      }),expression:"{\n                        arrow: true,\n                        arrowType: 'round',\n                        animation: 'fade',\n                        theme: 'light',\n                        maxWidth: 100\n                      }"}],attrs:{"fab":"","dark":"","small":"","depressed":"","color":"red","content":"unassign moderator"},on:{"click":function($event){return _vm.unAssign(moderator)}}},[_c('v-icon',[_vm._v("mdi-cancel")])],1)],2):_vm._e(),_c('div')],1)}),0):_c('div',{staticClass:"d-flex justify-center"},[_vm._v("loading...")])],1)],1)])})],2):_vm._e()],1),_c('div',{staticClass:"pb-1"},[_c('AssignModerator',{attrs:{"dialogMod":_vm.dialogMod,"item":_vm.item},on:{"close":_vm.closeDialog}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }