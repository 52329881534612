<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card class="radius-card pb-3" :loading="loading" min-height="150px">
        <v-toolbar flat dense><h3>Forum Management</h3></v-toolbar>

        <v-tabs v-model="tab">
          <v-tab href="#tab-1">Forum List</v-tab>
          <v-tab href="#tab-2">Moderator</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" style="background: none">
          <v-tab-item value="tab-1">
            <div v-if="forum">
              <v-data-table
                :headers="headers"
                :items="forum"
                :item-class="isClosed"
                hide-default-footer
                :page.sync="page"
                :items-per-page="perPage"
                class="elevation-0 my-5"
              >
                <template v-slot:[`item.creator`]="{ item }">
                  <div
                    v-for="(creator, idx) in item.relationships"
                    :key="`${idx}-user`"
                  >
                    <p v-if="creator.user" class="ma-0">
                      {{ creator.user.data.nama_lengkap }}
                    </p>
                  </div>
                </template>

                <template v-slot:[`item.created_at`]="{ item }">
                  {{ $date(item.created_at).format("DD, MMMM, YYYY") }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex">
                    <v-btn
                      icon
                      depressed
                      outlined
                      :color="item.status == 'open' ? 'green' : 'white'"
                      class="mr-2"
                      content="View thread"
                      v-tippy="{
                        arrow: true,
                        arrowType: 'round',
                        animation: 'fade',
                        theme: 'light',
                        maxWidth: 100
                      }"
                      :to="`/psikolog/thread/${item.id}`"
                    >
                      <v-icon dark small> mdi-eye </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      depressed
                      outlined
                      color="red"
                      content="Close thread"
                      v-tippy="{
                        arrow: true,
                        arrowType: 'round',
                        animation: 'fade',
                        theme: 'light',
                        maxWidth: 100
                      }"
                      @click.prevent="closeItem(item)"
                      v-if="item.status == 'open'"
                    >
                      <v-icon dark small> mdi-cancel </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      depressed
                      outlined
                      color="white"
                      content="open thread"
                      v-tippy="{
                        arrow: true,
                        arrowType: 'round',
                        animation: 'fade',
                        theme: 'light',
                        maxWidth: 100
                      }"
                      @click.prevent="closeItem(item)"
                      v-if="item.status == 'closed'"
                    >
                      <v-icon dark small> mdi-lock-open </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
              <div class="text-center" v-if="loading">Memuat ...</div>
              <div class="text-center pa-3">
                <v-pagination
                  v-model="page"
                  circle
                  @input="fetchItems"
                  :length="last_page"
                ></v-pagination>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <ModeratorManagement />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <div class="pb-1">
      <CloseThread
        v-bind:closeThread="closeThread"
        v-bind:item="item"
        @close="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import ModeratorManagement from "../../components/Admin/moderatorManagement.vue";
import CloseThread from "../../components/Admin/Modal/closeThread.vue";

export default {
  name: "ForumManagement",
  components: { ModeratorManagement, CloseThread },

  computed: {
    ...mapState({
      forum: state => state.forum.forum,
      env: state => state.API_URL,
      last_page: state => state.forum.last_page_forum
    })
  },

  data() {
    return {
      dialogAdd: false,
      tab: null,
      dialogUpdate: false,
      dialogMod: false,
      item: null,
      closeThread: false,
      search: "",
      loading: false,
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "TITLE", value: "judul", sortable: false },
        { text: "CATEGORY", value: "nama_kategori", sortable: false },
        { text: "CREATED BY", value: "creator", sortable: false },
        {
          text: "CREATED AT",
          value: `created_at`,
          sortable: false
        },
        { text: "ACTIONS", value: "actions", sortable: false }
      ],
      page: 1,
      perPage: 6
    };
  },

  mounted() {
    this.fetchItems(); //get all data when load page
  },

  methods: {
    fetchItems() {
      //get all data  fucntion
      this.loading = true;
      this.$store
        .dispatch("forum/listForum", {
          page: this.page,
          per_page: this.perPage
        })
        .then(() => {
          this.loading = false;
        });
    },

    isClosed: function(item) {
      if (item.status == "closed") {
        return "red darken-1 white--text";
      }
    },

    closeItem(item) {
      this.item = item;
      this.closeThread = true;
    },

    assignModerator(item) {
      this.item = item;
      this.dialogMod = true;
    },

    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store
            .dispatch("forum/deleteForum", { id: item.id })
            .then(() => {
              this.fetchItems(); //if success refetch data all
              Swal.fire({
                title: "Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },

    closeDialog() {
      //close dialog
      this.closeThread = false;
      this.fetchItems(); //and refetch data
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 90%;
}
</style>
