import { render, staticRenderFns } from "./moderatorManagement.vue?vue&type=template&id=b1abdb44&scoped=true&"
import script from "./moderatorManagement.vue?vue&type=script&lang=js&"
export * from "./moderatorManagement.vue?vue&type=script&lang=js&"
import style0 from "./moderatorManagement.vue?vue&type=style&index=0&id=b1abdb44&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1abdb44",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAvatar,VBtn,VCard,VIcon,VSpacer,VTab,VTabItem,VTabs,VToolbar})
