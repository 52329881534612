<template>
  <v-row justify="start">
    <v-dialog v-model="dialogMod" persistent max-width="500px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Assign Moderator</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pa-2">
          <v-container class="pa-0" v-if="psycholog">
            <v-text-field
              v-model="search"
              solo
              dense
              rounded
              append-icon="mdi-magnify"
              label="Cari"
              single-line
              hide-details
              @keyup="fetchPsycho"
            ></v-text-field>
            <v-card
              class="radius-card pa-2 my-3"
              :color="selected == item.id ? 'green' : 'white'"
              v-for="item in psycholog.data"
              :key="item.id"
              @click="select(item)"
            >
              <div class="d-flex justify-space-between align-center">
                <b :class="selected == item.id ? 'white--text' : ''">{{
                  item.nama_lengkap
                }}</b>
                <v-btn
                  icon
                  :color="selected == item.id ? 'white' : 'green'"
                  small
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </div>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="assign" v-if="selected"
            >Select this guy!</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "assignModerator",

  props: ["dialogMod", "item"],

  computed: {
    ...mapState({
      psycholog: state => state.admin.psycholog
    })
  },
  data() {
    return {
      selected: "",
      search: "",
      page: 1,
      perPage: 10
    };
  },
  mounted() {
    this.fetchPsycho();
  },
  methods: {
    select(item) {
      this.selected = item.id;
    },
    fetchPsycho() {
      let data = {
        page: this.page,
        per_page: this.perPage,
        search: this.search
      };
      this.loader = true;
      this.$store.dispatch("admin/listPsyholog", data).then(() => {
        this.loader = false;
      });
    },
    assign() {
      this.loader = false;
      let data = {
        id_user: this.selected,
        category: this.item
      };
      this.$store.dispatch("forum/assignMod", data).then(data => {
        this.loader = false;
        Swal.fire({
          icon: "success",
          title: data.message,
          showConfirmButton: false,
          position: "top",
          toast: true,
          timer: 3000
        });
      });
      this.$emit("close");
    }
  }
};
</script>

<style></style>
